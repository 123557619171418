/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { useObserver } from '@/hooks';

import { TextWrap, Text, Sign, FullScreenWrapper, LinkWrap, Link, StaticWrap, ItemWrap, LargeText, Label } from './styles';

const App = () => {
  const { file, contentJson } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "appertus.media.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER })
          }
        }
        contentJson(app: { id: { eq: "app" } }) {
          app {
            texts
            sign
            links {
              texts
              href
            }
            statistic {
              value
              description
            }
          }
        }
      }
    `
  );
  const { texts, sign, links, statistic } = contentJson.app;

  const { setPosition } = useObserver();

  return (
    <>
      <TextWrap onTouchStart={setPosition} onMouseEnter={setPosition}>
        {texts[0].map((text, key) => (
          <Text key={key}>{text}</Text>
        ))}
        <Sign>{sign}</Sign>
      </TextWrap>
      <FullScreenWrapper onTouchStart={setPosition} onMouseEnter={setPosition}>
        <GatsbyImage image={getImage(file)} alt="appertus.media.jpg" />
        <LinkWrap>
          {links.map(({ texts: linkTexts, href }, key) => (
            <Link key={key} href={href} target="_blank" rel="noreferrer nofollow">
              {linkTexts.map((text, i) => (
                <Fragment key={i}>
                  <span>{text}</span>
                </Fragment>
              ))}
            </Link>
          ))}
        </LinkWrap>
      </FullScreenWrapper>
      <TextWrap onTouchStart={setPosition} onMouseEnter={setPosition}>
        {texts[1].map((text, key) => (
          <Text key={key}>{text}</Text>
        ))}
      </TextWrap>
      <StaticWrap onTouchStart={setPosition} onMouseEnter={setPosition}>
        {statistic.map(({ value, description }, key) => (
          <ItemWrap key={key}>
            <LargeText>{value}</LargeText>
            <Label>{description}</Label>
          </ItemWrap>
        ))}
      </StaticWrap>
    </>
  );
};

export default App;
