import styled from 'styled-components';

import { fonts, palette } from '@/theme';

export const TextWrap = styled.div`
  padding: 10px 20px;
  @media (max-width: 991px) {
    padding: 6px 10px;
  }
`;

export const Text = styled.p`
  ${fonts.text}
  &+& {
    text-indent: 2em;
  }
  @media (max-width: 991px) {
    ${fonts.smallText22}
  }
`;

export const Sign = styled.p`
  ${fonts.smallText22}
  margin-top: 15px;
  @media (max-width: 991px) {
    ${fonts.smallText13}
    margin-top: 5px;
  }
`;

export const FullScreenWrapper = styled.div`
  position: relative;
  top: 0;
  left: -62px;
  width: calc(100% + 2 * 62px);
  border-top: 2px solid;
  border-color: ${palette.black};
  @media (max-width: 991px) {
    left: -16px;
    width: calc(100% + 2 * 16px);
    border-width: 1px;
  }
`;

export const LinkWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 2px solid ${palette.black};
  border-bottom: 2px solid ${palette.black};
  padding: 0 62px;
  & > a {
    width: calc(50% - 0.5px);
  }
  @media (max-width: 991px) {
    padding: 0;
    border-width: 1px;
    border-bottom: none;
  }
`;

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 165px;
  border: 5px solid ${palette.black};
  text-decoration: none;
  background-color: ${palette.black};
  color: ${palette.white};
  & > span {
    ${fonts.text}
    color: inherit;
  }
  &:active {
    background-color: ${palette.white};
    color: ${palette.black};
    width: calc(50% + 0.5px);
  }
  @media (hover) {
    &:hover {
      background-color: ${palette.white};
      color: ${palette.black};
      width: calc(50% + 0.5px);
    }
  }
  @media (max-width: 991px) {
    height: 70px;
    border-width: 3px;
    & > span {
      ${fonts.smallText22}
    }
  }
`;

export const StaticWrap = styled(FullScreenWrapper)`
  display: flex;
  padding: 0 62px;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 0 16px;
  }
`;

export const ItemWrap = styled.div`
  width: calc(100% / 3);
  & + & {
    border-left: 2px solid ${palette.black};
  }
  @media (max-width: 991px) {
    width: 100%;
    border-bottom: 1px solid ${palette.black};
    & + & {
      border-left: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const LargeText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 375px;
  ${fonts.large}
  @media (max-width: 1400px) {
    font-size: 120px;
  }
  @media (max-width: 1150px) {
    font-size: 100px;
  }
  @media (max-width: 991px) {
    min-height: 150px;
    ${fonts.lead}
  }
`;

export const Label = styled.p`
  border-top: 2px solid ${palette.black};
  ${fonts.smallText22}
  text-align: center;
  padding: 2px 15px;
  @media (max-width: 991px) {
    border-width: 1px;
    ${fonts.smallText13}
  }
`;
